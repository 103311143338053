import axios from 'axios';

import authHeader from './auth-header'

class AuthService {
  login(email: string, password: string) {
    return axios
      .post(process.env.VUE_APP_API_URL + '/login', {
        email,
        password
      })
      .then(response => {
        return response.data;
      });
  }

  logout() {
    return new Promise<any>((resolve, reject) => {
      localStorage.removeItem('token')
      localStorage.removeItem('user')
      resolve(true);
    })
  }

  register(username: string, email: string, password: string) {
    return axios.post(process.env.VUE_APP_API_URL + '/register', {
      username,
      email,
      password
    });
  }

  check() {
    return new Promise<any>((resolve, reject) => {
      let auth_header = null;

      try {
        auth_header = authHeader();
      } catch (error) {
        resolve('NO_TOKEN');
      }

      if(auth_header) {
        axios.get(process.env.VUE_APP_API_URL + '/check', {headers: auth_header})
        .then(() => {
          resolve('VALID');
        })
        .catch(() => {
          resolve('NOT_VALID');
        });
      }
    });

  }
}
export default new AuthService();
