import { createI18n } from 'vue-i18n'

const i18nConfig = {
  locale: 'en',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: {
    en: require('@/i18n/en.json'),
    de: require('@/i18n/de.json'),
  }
}

export default createI18n(i18nConfig)
