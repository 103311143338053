<template>
  <va-select
    class="mr-1 lang"

    target="body"
    :prevent-overflow="true"

    :options="options"
    :text-by="(item) => item.text.toUpperCase()"
    track-by="code"

    v-model="lang_select"
    color="#54565a"
    @update:model-value="setLanguage(lang_select)"
  >
  </va-select>
</template>

<script>
import { useGlobalConfig } from 'vuestic-ui'

export default {
  name: 'LangSwitcher',
  setup () {
    const theme = useGlobalConfig().getGlobalConfig().colors;

    return { theme }
  },
  data () {
    const options =  [
      {
        code: 'en',
        text: 'EN',
        name: 'english',
      },
      {
        code: 'de',
        text: 'DE ',
        name: 'deutsch',
      },
    ]

    const lang_select = options.filter((item) => {return item.code == this.$i18n.locale}).length >= 1 ? options.filter((item) => {return item.code == this.$i18n.locale})[0] : options[0];

    return {
      lang_select: lang_select,
      options: options,
    }
  },
  props: { },
  methods: {
    setLanguage (locale) {
      // this.$i18n.locale = locale.code;
      // localStorage.setItem('locale', this.$i18n.locale)

      localStorage.setItem('locale', locale.code)

      /*
      this.$router.push({ name: this.$route.name, params: { locale: this.$i18n.locale }})

      setTimeout(() => {
        this.$router.go()
      }, 100)
      */

      let _href = window.location.href

      let _url  = _href.split('?')[0]
      if(!_url.endsWith('/')) {
        _url += '/';
      }

      let _query = _href.split('?')[1]

      _href = _url.replace('/en/', '/<lang>/')
      _href = _href.replace('/de/', '/<lang>/')

      _href = _href.replace('<lang>', locale.code)

      if(_query) {
        _href += '?' + _query;
      }

      window.location.href = _href;
    },

    currentLanguage () {
      return this.$i18n.locale === 'en' ? 'en' : this.$i18n.locale
    },
  },
}
</script>

<style lang="scss">
@import "~flag-icon-css/css/flag-icon.css";

.lang {
  width: 80px;

  .va-input-wrapper__field:after {
    opacity: 0;
  }

  .va-input-wrapper__field {
    border: none;
  }

  .va-input-wrapper__text {
    width: auto;
    width: 24px;
    margin-right: 2px;
    font-family: 'Lato-Bold';
  }

  .va-input-wrapper__append-inner {
    margin-top: -2px;
  }
}
</style>
