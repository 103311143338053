<template>
  <div class="site-layout__navbar">
    <div class="site-layout__navbar-container">
      <div class="__mobile">
        <!--
        <div class="__mobile-container" @click="show_mobile_menu()">
          <img class="menu-icon" style="max-height: 32px; margin-right: 6px;" src="@/assets/svg/menu.svg" alt="X"/>
          <div>MENU</div>
        </div>
        -->
      </div>

      <div class="__lang" v-if="window_width > 620">
      </div>

      <div class="__logo">
        <a :href="'/' + $i18n.locale">
          <img v-if="window_width > 620" style="padding: 20px" class="logo" src="/realconcept_color.svg" alt="Logo"/>
          <img v-if="window_width <= 620" style="max-height: 56px;" class="logo" src="/realconcept_icon_color.png" alt="Logo"/>
        </a>
      </div>

      <div class="__lang">
        <LangSwitcher />
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { computed } from 'vue'
import { useColors } from 'vuestic-ui'

import { useSidebarStore } from '@/stores/sidebar.store'
import { useAuthStore } from '@/stores/auth.store'

import LangSwitcher from './components/LangSwitcher.vue'

export default defineComponent({
  components: { LangSwitcher },
  setup () {
    const { getColors } = useColors()
    const colors = computed(() => getColors() )

    const sidebarStore = useSidebarStore();
    const authStore = useAuthStore();

    const isSidebarMinimized = computed({
      get: () => sidebarStore.isSidebarMinimized,
      set: (value) => sidebarStore.updateSidebarCollapsedState(value)
    })

    const user = computed(() => authStore.user)

    return {
      colors,
      isSidebarMinimized,
      user,
    }
  },

  data () {
    return {
      window_width: window.innerWidth,
      logo: null,
      icon: null,
    }
  },

  methods: {
    menu_mouse_enter (item) {
      this.menu_show = true;
      this.active_item = item;
      this.active_item.articles = this.active_item.articles.filter((article) => { return article.languages.includes(this.$i18n.locale)})
    },

    menu_mouse_leave () {
      this.menu_show = false;
      this.active_item = null;
    },

    onResize () {
      this.window_width = window.innerWidth;
    },

    show_mobile_menu () {
      this.mobile_menu_show = true;
      this.mobile_menu_show_class = true;

      this.mobile_active_item = this.menus[0];
    },

    close_mobile_menu () {
      this.mobile_menu_show_class = false;

      setTimeout(() => {
        this.mobile_menu_show = false;
      }, 500)
    },
  },

  beforeDestroy () {
    window.removeEventListener('resize', this.onResize);
  },

  mounted() {
    setTimeout(() => {
      window.addEventListener('resize', this.onResize);
    }, 100)
  },
})
</script>

<style lang="scss" scoped>
  .coundown-col {
    display: flex;
    flex-direction: column;

    width: calc(100% / 4);
  }

  .countdown-time {
    width: auto !important;
    font-size: 14px;

    color: #242424 !important;
  }

  .countdown-label {
    // font-size: 9px;
    // font-weight: 600;
    // line-height: 4px !important;

    font-size: 9px;
    font-weight: 600;
    line-height: 8px !important;
    font-family: 'Lato-Bold';
    color: #838383;
  }

  .countdown {
    display: flex;

    margin-top: 2px;

    margin-left: 8px;
    margin-right: 8px;

    &-col {
      display: flex;
      flex-direction: column;


    }
  }

  .__mobile-menu {
    position: absolute;
    top: 0;
    // left: 0;
    height: 100vh;
    width: 100vw;
    background: #fff;

    transform: translateX(-100vw);
    animation: slide-out 0.5s;

    &.active {
      // left: 0;
      transform: translateX(0%);
      animation: left-slide 0.5s;
    }

    &-container {
      display: flex;
      height: 100%;

      &-nav {
        width: 100px;
        background: #847a3f;

        &-tile {
          position: relative;

          cursor: pointer;

          display: flex;
          justify-content: center;
          align-items: center;

          height: 90px;

          color: #fff;
          font-family: 'Lato-Bold';

          &:after {
            content: "";
            position: absolute;
            height: 1px;
            width: 100%;
            background: #fff;

            bottom: 0;
          }

          &.active {
            background: #fff;
            color: var(--va-primary)
          }
        }
      }

      &-content {
        flex: 1;

        &-title {
          display: flex;
          align-items: center;
          height: 90px;
          border-bottom: 1px solid #eee;

          &-text {
            flex: 1;
            text-transform: uppercase;
            padding-left: 20px;
            font-size: 20px;
          }
        }

        &-links {
          display: flex;
          flex-direction: column;

          width: 100%;
          padding-left: 20px;
          padding-top: 20px;

          &-item {
            border-bottom: 1px solid #e1e1e1;
            margin-right: 45px;
            font-size: 1.2em;
            color: #242424;

            &:hover {
              a {
                color: var(--va-primary);
              }
            }

            a {
              color: #242424;
              display: block;
              padding: 8px 0;
              font-size: 20px;
              line-height: 28px;
            }
          }

          &-button {
            width: fit-content;
            cursor: pointer;

            background-color: var(--va-primary);
            color: #fff;

            padding: 6px 12px;
            margin-top: 20px;

            font-size: 16px;
            text-transform: uppercase;

            border-radius: 2px;
          }
        }
      }
    }
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;

    height: 100vh;

    // height: calc( 100vh - 400px);
    // top: 400px;

    z-index: -2;

    opacity: 0;
    background: #000;

    animation: opacity-change 2s reverse;

    &.active {
      opacity: .2;
      animation: opacity-change 2s;
    }

    @media (max-width: 1024px) {
      display: none;
    }
  }

  .site-layout__navbar {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background: #fff;
    z-index: 900;

    &-container {
      max-width: 1280px;
      max-height: 94px;
      height: 94px;
      margin: 0 auto;
      display: flex;
      flex-flow: row wrap;
      padding-top: 17px;
      border-bottom: 1px solid #e1e1e1;
      background: #fff;

      @media (max-width: 1024px) {
        padding-top: 0;
      }

      .__mobile {
        display: flex;
        align-items: center;
        padding-left: 20px;
        font-size: 20px;

        cursor: pointer;

        width: 80px;

        @media (min-width: 1024px) {
          display: none;
        }

        &-container {
          display: flex;
          align-items: center;
        }
      }

      .__logo {
        flex: 1;
        display: flex;
        justify-content: center;

        @media (max-width: 1024px) {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        a {
          .logo {
            height: 76px;
            vertical-align: middle;
            margin-right: 5px;
            object-fit: contain;

            @media (max-width: 620px) {
              width: 100%;
              max-height: 76px;
              max-width: 62px;
              height: auto;
            }
          }
        }
      }

      .__menu {
        display: flex;
        flex: 1 0 auto;
        align-items: center;

        @media (max-width: 1024px) {
          display: none;
        }

        .__expand {
          width: 100%;
          min-height: 400px;
          background: #fff;
          position: absolute;
          left: 0;
          top: 0;
          z-index: -1;

          padding: 124px 0 30px;

          animation: slide-in-reverse 0.5s;

          &.inactive {
            transform: translateY(-100%);
          }

          &.active {
            animation: slide-in 0.5s;
            transform: translateY(0%);
          }

          &-container {
            width: calc(99% - 90px);
            max-width: 1190px;
            display: flex;
            position: relative;
            margin: 0 auto;
            background: #fff;
            padding-left: 70px;
            text-align: left;

            &-box {
              width: 100%;
              display: flex;

              &-links {
                display: flex;
                flex-direction: column;
                width: 25%;

                &-item {
                  border-bottom: 1px solid #e1e1e1;
                  margin-right: 45px;
                  font-size: 1.2em;
                  color: #242424;

                  &:hover {
                    a {
                      color: var(--va-primary);
                    }
                  }

                  a {
                    color: #242424;
                    display: block;
                    padding: 8px 0;
                    font-size: 16px;
                    font-weight: 400;
                    // font-family: 'Lato';
                  }
                }

                &-button {
                  width: fit-content;
                  cursor: pointer;

                  background-color: var(--va-primary);
                  color: #fff;

                  padding: 6px 12px;

                  font-size: 14px;
                  text-transform: uppercase;

                  border-radius: 2px;
                }
              }

              &-articles {
                display: flex;

                &-item {
                  width: 260px;
                  height: 180px;
                  margin-right: 20px;

                  color: #242424 !important;

                  cursor: pointer;

                  &:hover {
                    .__expand-container-box-articles-item-title {
                      color: var(--va-primary)
                    }
                  }

                  &-thumbnail {
                    width: 100%;
                    height: 100%;

                    img {
                      width: 100%;
                      height: 100%;
                      object-fit: cover;

                      border: 1px solid #eee
                    }
                  }

                  &-title {
                    font-family: 'Lato-Bold';
                    font-size: 16px;
                    line-height: 18px;
                    margin-top: 12px;
                  }
                }
              }
            }
          }
        }

        &__items {
          height: 100%;
          flex: auto;
          display: flex;
          flex-direction: row;
          align-items: center;

          margin: auto;
          max-width: 600px;

          &-links {
            width: 100%;
            height: 100%;
            display: flex;

            .item {
              flex: 1 1 auto;
              font-size: 15px;
              font-family: 'Lato-Bold';
              text-transform: uppercase;
              height: 100%;
              text-align: center;

              display: flex;
              justify-content: center;
              align-items: center;

              cursor: pointer;

              position: relative;

              &.active {
                a {
                  color: var(--va-primary);
                }

                &:before {
                  content: "";
                  width: 60%;
                  height: 4px;
                  background: var(--va-primary);
                  position: absolute;
                  bottom: 0;
                  border-radius: 4px;
                }
              }

              a {
                display: inline-block;
                height: 50px;
                top: -14px;
                line-height: 71px;
                max-height: 76px;
                transition: all .2s ease;
                position: relative;
                padding-top: 2px;
                box-sizing: border-box;
                color: #242424;
                font-family: 'Lato-Bold';

                width: 100%;
              }
            }
          }
        }
      }

      .__sponsors {
        display: flex;
        justify-content: center;
        align-items: center;

        position: relative;
        padding: 12px;

        @media (max-width: 1024px) {
          display: none;
        }

        &-container {
          position: relative;
          display: flex;

          &-item {
            display: flex;
            justify-content: center;
            align-items: center;

            position: relative;
            padding-left: 12px;
            padding-right: 12px;

            span {
              text-align: center;
              line-height: 18px;

              &:first-child {
                font-family: 'Lato-Bold';
                color: var(--va-primary);
                width: 140px;
              }
            }

            img {
              width: 86px;
            }

            &:after {
              content: "";
              position: absolute;
              right: 0;
              top: 50%;
              width: 0;
              height: 30px;
              border-right: 1px solid #e1e1e1;
              transform: translateY(-50%);
            }
          }
        }
      }

      .__lang {
        width: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .va-navbar {
    box-shadow: var(--va-box-shadow);
    z-index: 2;
    &__center {
      @media screen and (max-width: 1200px) {
        .app-navbar__github-button {
          display: none;
        }
      }
      @media screen and (max-width: 950px) {
        .app-navbar__text {
          display: none;
        }
      }
    }

    @media screen and (max-width: 950px) {
      .left {
        width: 100%;
      }
      .app-navbar__actions {
        width: 100%;
        display: flex;
        justify-content: space-between;
      }
    }
  }

  .left {
    display: flex;
    align-items: center;
    & > * {
      margin-right: 1.5rem;
    }
    & > *:last-child {
      margin-right: 0;
    }
  }

  .x-flip {
    transform: scaleX(-100%);
  }

  .va-navbar__item {
    cursor: pointer;
  }

  .app-navbar__text > * {
    margin-right: 0.5rem;
    &:last-child {
      margin-right: 0;
    }
  }

  @keyframes left-slide {
    0% {
      transform: translateX(-100vw);
    }
    100% {
      transform: translateX(0%);
    }
  }

  @keyframes slide-in {
    0% {
      transform: translateY(-100%);
    }
    100% {
      transform: translateY(0%);
    }
  }

  @keyframes slide-in-reverse {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(-100%);
    }
  }

  @keyframes slide-out {
    0% {
      transform: translateX(0%);
    }
    100% {
      transform: translateX(-100vw);
    }
  }

  @keyframes opacity-change {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 0.2;
    }
  }
</style>
