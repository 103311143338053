import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

import SiteLayout from '@/layout/site-layout.vue'
import FullscreenLayout from '@/layout/fullscreen-layout.vue'
import Page404Layout from '@/layout/page-404-layout.vue'

import i18n from '../i18n'

const setI18N = (locale:string) => {
  switch(locale) {
    case 'de':
      i18n.global.locale = 'de';
      break;
    case 'en':
      i18n.global.locale = 'en';
      break;
    default:
      i18n.global.locale = 'en';
  }
}

const getI18nLocale = () : string => {
  if(localStorage.getItem('locale')) {
    setI18N(localStorage.getItem('locale')!);

    return i18n.global.locale
  } else {
    let userLang = navigator.language;

    if(userLang.includes('de')) {
      i18n.global.locale = 'de'
      localStorage.setItem('locale', i18n.global.locale)
      return i18n.global.locale
    }

    if(userLang.includes('en')) {
      i18n.global.locale = 'en'
      localStorage.setItem('locale', i18n.global.locale)
      return i18n.global.locale
    }
  }

  return i18n.global.locale;
}

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: SiteLayout,
    beforeEnter: (to:any, from:any, next:any) => {
      let userLang = navigator.language;

      if(userLang.includes('de')) {
        i18n.global.locale = 'de'
        localStorage.setItem('locale', i18n.global.locale)
        return next('de' + to.path)
      }

      if(userLang.includes('en')) {
        i18n.global.locale = 'en'
        localStorage.setItem('locale', i18n.global.locale)
        return next('en' + to.path)
      }

      return next('en')
    },
  },

  {
    path: '/:locale',
    component: SiteLayout,
    beforeEnter: (to:any, from:any, next:any) => {
      const locale = to.params.locale.toString();

      const supported_locales = process.env.VUE_APP_I18N_SUPPORTED_LOCALE?.split(',');

      let userLang = navigator.language;

      if (!supported_locales?.includes(locale)) {
        if(userLang.includes('de')) {
          i18n.global.locale = 'de'
          localStorage.setItem('locale', i18n.global.locale)
          return next('de' + to.path)
        }

        if(userLang.includes('en')) {
          i18n.global.locale = 'en'
          localStorage.setItem('locale', i18n.global.locale)
          return next('en' + to.path)
        }

        return next('en')
      }

      if (i18n.global.locale !== locale) {
        i18n.global.locale = locale;
      }

      return next()
    },
    children: [
      {
        name: '404',
        path: '404',
        component: () => import('@/pages/site/404/404.vue'),
      },
      {
        name: 'home',
        path: '',
        component: () => import('@/pages/site/home/Home.vue'),
      },
      {
        name: 'job/:jid',
        path: 'job/:jid',
        component: () => import('@/pages/site/jobs/JobPage.vue'),
      },
    ]
  },
  {
    path: '/404',
    component: Page404Layout,
    children: [
      {
        name: '',
        path: '',
        component: () => import('@/pages/404-pages/VaPageNotFoundSimple.vue'),
      },
      {
        name: 'not-found-advanced',
        path: 'not-found-advanced',
        component: () => import('@/pages/404-pages/VaPageNotFoundSearch.vue'),
      },
      {
        name: 'not-found-simple',
        path: 'not-found-simple',
        component: () => import('@/pages/404-pages/VaPageNotFoundSimple.vue'),
      },
      {
        name: 'not-found-custom',
        path: 'not-found-custom',
        component: () => import('@/pages/404-pages/VaPageNotFoundCustom.vue'),
      },
      {
        name: 'not-found-large-text',
        path: '/pages/not-found-large-text',
        component: () => import('@/pages/404-pages/VaPageNotFoundLargeText.vue'),
      },
    ],
  },
  {
    path: '/:catchAll(.*)',
    redirect: (to:any) => {
      return { path: '/' + getI18nLocale() + '/404' }
    },
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  //  mode: process.env.VUE_APP_ROUTER_MODE_HISTORY === 'true' ? 'history' : 'hash',
  routes
})

router.beforeEach(async (to, from, next) => {

  next();
})

export default router
