import CryptoJS  from 'crypto-js';

const getPerists = (key: any): any => {
  let pre_value = localStorage.getItem(key) ? localStorage.getItem(key) : null;

  if(!pre_value) {
    return null;
  }

  let decrypted_value = CryptoJS.AES.decrypt(pre_value, process.env.VUE_APP_STORE_SECRET ? process.env.VUE_APP_STORE_SECRET : 'SECRET').toString(CryptoJS.enc.Utf8);

  return JSON.parse(decrypted_value || '{}');
}

export default function authHeader() {
  const user = getPerists('user');
  const token = getPerists('token');

  if (user && token) {
    return { 'Authorization': 'Bearer ' + token };
  } else {
    return {};
  }
}
