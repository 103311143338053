<template>
  <div class="footer">
    <div class="footer-content">
      <div class="footer-content-box">
        <div class="footer-content-box-item">
          <img src="/realconcept_white.svg" alt="Logo"/>
        </div>

        <div class="footer-content-box-item flex-column">
          <div class="footer-content-box-item-text">© {{ new Date().getFullYear() }} - RealConcept Gesellschaft für Beteiligungen mbH</div>
          <div class="footer-content-box-item-text"><a href="https://realconcept-gmbh.net/impressum/">{{ $t(`itf.footer.imprint`) }}</a></div>
          <div class="footer-content-box-item-text"><a href="https://realconcept-gmbh.net/datenschutz/">{{ $t(`itf.footer.privacy_policy`) }}</a></div>
        </div>

        <div class="footer-content-box-item flex-column">
          <div class="footer-content-box-item-text" style="font-size: 16px; font-family: Lato-Bold">{{ $t('footer.languages') }}</div>
          <div class="footer-content-box-item-text"><a href="/de">Deutsch</a></div>
          <div class="footer-content-box-item-text"><a href="/en">English</a></div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Footer',
  components: { },
  props: [ ],

  data () {
    return {
      logo_white: null,
    }
  },

  methods: { },

  mounted () { }
});
</script>

<style lang="scss" scoped>
  .flex-column {
    flex-direction: column;
    justify-content: left;
    align-items: center;
  }

  .footer {
    position: relative;

    &-container {
      max-width: 1280px;
      width: 100%;
      margin: 0 auto;
      margin-top: 80px;
      padding-left: 45px;
      padding-right: 45px;

      @media (max-width: 620px) {
        padding-left: 20px;
        padding-right: 20px;
      }
    }

    &-content {
      margin-top: 12px;
      background: #847a3f;
      min-height: 100px;
      padding-top: 45px;
      padding-bottom: 45px;

      &-box {
        max-width: 1280px;
        width: 100%;
        margin: 0 auto;
        padding-left: 45px;
        padding-right: 45px;

        display: flex;

        @media (max-width: 900px) {
          flex-direction: column;
          padding-left: 20px;
          padding-right: 20px;
        }

        &-item {
          display: flex;
          align-items: center;
          border-right: 1px solid #fff;
          padding: 10px 40px;

          img {
            height: 40px;
          }

          &-text {
            display: flex;
            width: 100%;
            color: #fff;
            font-size: 14px;
            line-height: 24px;

            text-align: left;

            a {
              color: #fff;
              text-decoration: underline
            }
          }
        }
      }
    }
  }
</style>
